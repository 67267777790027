import styled from '@emotion/styled/macro';
import { Environment, getEnvironment } from '@sunrun/sales-experience-shared';
import { QuickLink } from 'components/molecules/QuickLink';
import { publish } from 'helpers/messaging';

type QuickLinksProps = {
  isCEA: boolean;
  isChannelPartner: boolean;
  isD2H: boolean;
  isFusion: boolean;
};

const RETAIL_APP_URL: Record<Environment, string> = {
  production: 'https://sunrun.lightning.force.com/c/RetailApp.app',
  staging: 'https://sunrun--majstg.sandbox.lightning.force.com/c/RetailApp.app',
  relcert: '',
  development: '',
};

const env = getEnvironment();

const QuickLinks = ({
  isChannelPartner,
  isCEA,
  isD2H,
  isFusion,
}: QuickLinksProps) => {
  return (
    <Container>
      <QuickLink
        text="Create a Lead"
        href="#lead"
        onClick={() => {
          publish.createLead();
        }}
      />
      {!isChannelPartner && (
        <>
          <QuickLink
            text="My Training"
            href="https://sunrun.docebosaas.com/"
            target="_blank"
          />
          <QuickLink
            text="My Apps"
            href="https://sunrun.okta.com/app/UserHome"
            target="_blank"
          />
          <QuickLink
            text="Company News"
            href="http://current.sunrun.com/"
            target="_blank"
          />
          <QuickLink
            text="Resource Center"
            href="https://sites.google.com/sunrun.com/sales"
            target="_blank"
          />
        </>
      )}
      {isChannelPartner && (
        <QuickLink
          text="Training"
          href="https://sunrun.docebosaas.com/partner/learn"
          target="_blank"
        />
      )}
      {isCEA && (
        <QuickLink
          text="Retail App"
          href={RETAIL_APP_URL[env]}
          target="_blank"
        />
      )}
      {(isD2H || isFusion) && (
        <QuickLink
          text="Open Terros"
          onClick={() => {
            publish.openTerros();
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
`;

export { QuickLinks };
